import {
    defaultTheme,
    Theme,
    ThemeProvider,
    TiaGlobalCSS,
    TiaModalProvider
} from '@asktia/tia-ui'
import { HelmetProvider } from 'react-helmet-async'
import { QueryClient, QueryClientProvider } from 'react-query'

import {
    BrowserRouter as Router,
    Navigate,
    Route,
    Routes,
    useParams
} from 'react-router-dom'
import { Show404 } from 'src/flows/404'
import { AppointmentBooking } from 'src/flows/AppointmentBooking'
import { AutomatedCheckin } from 'src/flows/AutomatedCheckin'
import { AppointmentChange } from 'src/flows/AppointmentChange'
import { NPSContextProvider } from './components/NPSModal'
import { AmpliIdentify } from 'src/components/itly/AmpliIdentify'
import { YourCare } from 'src/pages/YourCare'
import { AccountInfo } from 'src/pages/AccountInfo'
import { ChangeCancelMembership } from 'src/pages/Membership/ChangeCancel'
import { CancelMembership } from 'src/pages/Membership/Cancel'
import { AppointmentHistory } from 'src/pages/AppointmentHistory'
import ChatRouter from 'src/pages/Chat'
import { Plan } from 'src/pages/YourCare/Plan'
import { InterventionDetail } from 'src/pages/YourCare/InterventionDetail'
import { AppointmentRedirect } from 'src/flows/AppointmentRedirect'
import { Homepage } from 'src/pages/Home'
import { Authentication } from 'src/flows/Authentication'
import { Landing } from './pages/Landing'
import { AppointmentsPage } from './pages/Appointments'
import { Uhr } from 'src/flows/questionnaires/Uhr'
import { DevTools } from 'src/DevTools'
import { AmplitudeExperimentProvider } from 'src/AmplitudeExperimentProvider'
import { BookPreventativeCare } from 'src/pages/Home/BookPreventativeCare'
import { MentalHealth } from 'src/flows/questionnaires/MentalHealth/MentalHealth'
import { PhysicalHealth } from 'src/flows/questionnaires/PhysicalHealth/PhysicalHealth'
import { HealthRecord } from 'src/pages/YourCare/HealthRecord'
import ErrorBoundary from 'src/logger/ErrorBoundary'
import EncounterDetail from 'src/pages/AppointmentHistory/EncounterDetail'
import { AppointmentHistoryList } from 'src/pages/AppointmentHistoryList'
import AppointmentBillingList from 'src/pages/AppointmentBillingList'

import { DevSidebarProvider } from 'src/DevTools/DevSideBar'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { AppointmentSuggestionDetail } from 'src/pages/AppointmentSuggestion/Detail'
import { MedicalHistory } from 'src/flows/questionnaires/MedicalHistory'
import { MedplumProvider } from '@medplum/react'
import { medplum } from 'src/medplum'
import Intercom from '@intercom/messenger-js-sdk'

const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || ''
)

const queryClient = new QueryClient()
const INTERCOM_APP_ID = String(process.env.REACT_APP_INTERCOM_APP_ID)

const showDevtools =
    window.location.hostname.includes('localhost') ||
    window.location.hostname.includes('public.dev') ||
    window.location.hostname.includes('public.staging')

// We need this redirect because some traffic still going to
// /book-appointment/services/:blah
const ServiceLineRedirect = () => {
    const params = useParams()

    return (
        <Navigate
            to={`/appointments/?serviceLines=${params.serviceLineName}`}
        />
    )
}

const RouteDefinitions = () => (
    <Routes>
        <Route path="/login/*" element={<Authentication />} />
        <Route path="/landing/*" element={<Landing />} />

        <Route path="/appointment-history">
            <Route path=":appointmentUuid" element={<EncounterDetail />} />
            <Route path="" element={<AppointmentHistory />} />
        </Route>
        <Route path="/appointment-history-list">
            <Route path="" element={<AppointmentHistoryList />} />
        </Route>
        <Route path="/appointment-billing-list">
            <Route path=":appointmentUuid" element={<EncounterDetail />} />
            <Route path="" element={<AppointmentBillingList />} />
        </Route>
        <Route path="/automated-checkin/*" element={<AutomatedCheckin />} />

        <Route path="/book-appointment" element={<Navigate to="/" />} />
        <Route
            path={`/book-appointment/services/:serviceLineName`}
            element={<ServiceLineRedirect />}
        />

        <Route path="/book-appointment/*" element={<AppointmentBooking />} />
        <Route
            path="/book-preventative-care"
            element={<BookPreventativeCare />}
        />
        <Route path="/" element={<Homepage />} />
        <Route path="/change-appointment/*" element={<AppointmentChange />} />

        <Route path="/chat/*" element={<ChatRouter />} />

        <Route
            path="/appointment/:appointmentUuid"
            element={<AppointmentRedirect />}
        />

        <Route path="/your-care">
            <Route
                path="plan/:carePlanInterventionUuid"
                element={<InterventionDetail />}
            />
            <Route path="plan" element={<Plan />} />
            <Route path="health-record/*" element={<HealthRecord />} />
            <Route path="" element={<YourCare />} />
        </Route>

        <Route path="/account-info" element={<AccountInfo />} />
        <Route path="/membership">
            <Route path="change-cancel" element={<ChangeCancelMembership />} />
            <Route path="cancel" element={<CancelMembership />} />
        </Route>

        <Route path="/appointments" element={<AppointmentsPage />} />

        <Route path="/uhr/*" element={<Uhr />} />

        <Route path="/medical-history/*" element={<MedicalHistory />} />

        <Route path="/mental-health/*" element={<MentalHealth />} />

        <Route path="/physical-health/*" element={<PhysicalHealth />} />

        <Route path="/suggestions">
            <Route
                path=":appointmentSuggestionUuid"
                element={<AppointmentSuggestionDetail />}
            />
        </Route>

        <Route element={<Show404 />} />
    </Routes>
)

function App() {
    Intercom({
        app_id: INTERCOM_APP_ID
    })

    return (
        <ErrorBoundary>
            <HelmetProvider>
                <TiaGlobalCSS />
                <ThemeProvider
                    theme={defaultTheme as typeof defaultTheme & Theme}
                >
                    <QueryClientProvider client={queryClient}>
                        <MedplumProvider medplum={medplum}>
                            <Elements stripe={stripePromise}>
                                <DevSidebarProvider>
                                    <Router basename="/r/">
                                        <AmplitudeExperimentProvider>
                                            <TiaModalProvider>
                                                <NPSContextProvider>
                                                    <AmpliIdentify />

                                                    <RouteDefinitions />
                                                </NPSContextProvider>

                                                {showDevtools && <DevTools />}
                                            </TiaModalProvider>
                                        </AmplitudeExperimentProvider>
                                    </Router>
                                </DevSidebarProvider>
                            </Elements>
                        </MedplumProvider>
                    </QueryClientProvider>
                </ThemeProvider>
            </HelmetProvider>
        </ErrorBoundary>
    )
}

export default App
